<template>
  <div>

    <!-- 3D   greenball  blueball-->
    <div class="res-open-content " :class="isqiansigame?fantanclass[4]:fantanclass[list[0].codeArr.length]">
      <div class="r-o-ssg">
        <table class="com-table">
          <tr>
            <th>期数</th>
            <th width="140">时间</th>
            <th :colspan="isqiansigame?4:list[0].codeArr.length">开出号码</th>
            <!-- <th>和</th> -->
          
            <!-- <th>大小</th> -->
            <th>单双</th>
            <th>结果</th>

          </tr>
          <tr v-for="item in list" :key="item.Expect">
            <td>{{item.Expect}}</td>
            <td>{{item.Opentime|substrSome}}</td>
            <template v-if="item.codeArr.length==5||item.codeArr.length==8"  >
              <template v-for="(code,i) in item.codeArr"  >
              <td v-if="showorhidenum5(i)" :key="code+i" class="td-ball">
              <p   :class="'blueball b'+code"></p>
            </td>
          </template>
          </template>
          <template v-if="item.codeArr.length==10">
            <td v-for="code in item.codeArr" :key="code" class="balls_pk td-ball">
              <p :class="'b'+code"></p>
            </td>
            </template>
            <template v-if="item.codeArr.length==3">
              <td class="td-ball" v-for="code in item.codeArr" :key="code">

<p :class="'ball b'+code"></p>
</td>
            </template>

            <!-- <td>
              <span>{{ item.sum }}</span>
            </td> -->
          
            <!-- <td>
              <span :class="fontColor[item.daxiao]">{{ item.daxiao }}</span>
            </td> -->

            <td :class="fontColor[item.ds]">{{item.ds}} </td>
            <td>
              <span>{{ item.fan }}</span>
            </td>
          </tr>
        </table>
        <div class="page">
          <!-- :page-sizes="[20,50,100,200]" -->
          <el-pagination @current-change="handleCurrentChange" :current-page="pages.pagenum" :page-size="pages.pagecount" layout="total, prev, pager, next, jumper" :total="totals">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import kjmixins from "../mixins/kjmixins";
export default {
  name: "kjfantan",
  props: [""],
  mixins: [kjmixins],
  data() {
    return {
      fantanclass:{
        10:'type-pks yzpks',
        5:'type-ssg lgklsf',
        4:'type-ssg lgklsf',
        8:'type-ssg lgklsf',
        3:'pcdd'
      }
    };
  },

  components: {},
  created() {},
  computed: {},

  methods: {},
};
</script>
<style  scoped>
.r-o-ssg .com-table tr td.ds_d,
.r-o-ssg .com-table tr td.dx_d {
  color: red;
}
.r-o-ssg .com-table tr td.ds_s,
.r-o-ssg .com-table tr td.dx_x {
  color: #00f;
}
li,
ul {
  list-style: none;
}
</style>