import { mapState } from "vuex";

var mixins = {
  data() {
    return {
      pages: {
        pagenum: 1,
        pagecount: 20,
      },
      fontColor: {
        大: "ltred",
        小: "ltblue",
        单: "ltred",
        双: "ltblue",
        和: "ltgreen",
        质: "ltred",
        合: "ltblue",
        龙: "ltred",
        虎: "ltblue",
        蓝: "ltblue",
        红: "ltred",
        绿: "ltgreen",
        极大:'ltblue',
        极小:'ltred',
        杂六:"ltgreen",
        对子:"ltbro",
        顺子:"ltblue",
        豹子:'ltred'
      },
      list: [],
      queryParam: {},
      //   游戏类型
      gametype: "",
      totalPage: 1,
      totals: 1,
      frequency: "",
    };
  },
  props: [],
  watch: {
    kjDate(val) {
      this.queryParam.begindate = val;
      this.queryParam.enddate = val;
    },
    searchkjcount() {
      this.pages.pagenum = 1;
      this.getList();
      console.log(123)
    },
    "$route.query": {
      immediate: true,
      handler(val) {
        this.frequency = val.frequency;
        this.gametype = val.gametype;
        this.queryParam.roomeng = val.roomeng;
        this.queryParam.begindate = val.kjDate;
        this.queryParam.enddate = val.kjDate;
        this.queryParam.gametype=val.gametype
        this.pages.pagenum = 1;
        this.getList();
     
      },
    },
  },
  computed: {
    ...mapState(["kjDate",'searchkjcount', "userInfo", "resetCount", "sxArr"]),
    isqiansigame(){
      let b=false
     if(this.$route.query.roomeng=='fantan'||this.$route.query.roomeng=='jsfantan'){
      b=true
     }
      return b
    },
  },
  created() {},
  methods: {
   
    showorhidenum5(i){
     
      let b=true
      if(this.queryParam.roomeng=='fantan'||this.queryParam.roomeng=='jsfantan'){
        if(i==4){
          b=false
        }
      }
      return b
    },
    getList() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        ...this.queryParam,
        ...this.pages,
      };

      if (this.frequency == "day") {
        let year = new Date().getFullYear();
        obj.begindate = year-1 + "0101";
        obj.enddate = this.splitDate(this.getBeforeDate(0));
      }

      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          this.list = resObj.data;
          // console.log(this.list, "六合彩开奖数据");
          // if (this.$options.name == "pk10") {
          this.list.forEach((item) => {
            if(item.Opencode.includes("+")){
              let jiaArr=item.Opencode.split('+');
              item.codeArr=jiaArr[0].split(",")
              item.ds = Number(jiaArr[1])%2==0?'双':'单'
              item.fan=jiaArr[1]+'番'
            }else{
              item.codeArr = item.Opencode.split(",");
            }
         
            // 判断路图  快乐十分
            if (
              this.$options.name == "kjklsf" ||
              this.$options.name == "kjnc"
            ) {
              item.lutuResult = this.klsflutu(item.codeArr);
            }
            // 排列3
            if (this.$options.name == "kjpl3") {
              item.lutuResult = this.pl3lutu(item.codeArr);
            }
            // 时时彩
            if (this.$options.name == "kjssc" || this.$options.name == "kjsx") {
              item.lutuResult = this.ssclutu(item.codeArr);
            }
            // 11选5
            if (this.$options.name == "kjsyx5") {
              item.lutuResult = this.syx5lutu(item.codeArr);
            }
            // kjk3 快3
            if (this.$options.name == "kjk3") {
              item.lutuResult = this.k3lutu(item.codeArr);
            }
            // PK10
            if (this.$options.name == "kjpk10") {
              item.lutuResult = this.pk10lutu(item.codeArr);
            }
            //快乐8
            if (this.$options.name == "kjkl8") {
              let sum = 0;
              // item.codeArr.splice(item.codeArr.length - 1);
              let sarr = item.codeArr.slice(0, 20);
              item.codeArr = sarr;
              item.codeArr.forEach((ele) => {
                sum += Number(ele);
              });
              item.sum = sum;
              item.ds = sum % 2 == 0 ? "双" : "单";
              if (sum == 810) {
                item.dx = "和";
              } else if (sum > 810) {
                item.dx = "大";
              } else {
                item.dx = "小";
              }
            }
          });

          //
          if (this.$options.name == "kjlhc") {
            this.list.forEach((item) => {
              // let last = item.codeArr.splice(5, 1);
              // let las2 = last[0].split("+");
              // item.codeArr = item.codeArr.concat(las2);
              item.sxArr = [];
              let nianxiaoItem=this.getNianXiao(item.Opentime)
              item.codeArr.forEach((ele) => {
                nianxiaoItem.sxArr.forEach(ite=>{
                  let hadEle=ite.nums.find(num=>Number(num)==Number(ele))
                  if(hadEle){
                    item.sxArr.push(ite.xiao);
                  }
                })
                // let sxItem = this.sxArr.find((item) => item.ball == ele);
                // item.sxArr.push(sxItem.shengxiao);
              });
              item.lutuResult = this.lhclutu(item.codeArr);
            });
          }
          // pc蛋蛋
          if (this.$options.name == "kjpcdd") {
            this.list.forEach((item) => {
              let sum = 0;
              item.codeArr.forEach((num) => {
                sum += Number(num);
              });
              let setarr=new Set(item.codeArr)
              let newarr=Array.from(setarr)
           
              
              if(newarr.length==1){
                item.xingtai='豹子'
              }else if(newarr.length==2){
                item.xingtai='对子'
              }else if(newarr.length==3){
                item.xingtai='杂六'
                newarr.sort((a,b)=>Number(b)-Number(a))
                if(Number(newarr[0])-Number(newarr[1])==1&&Number(newarr[1])-Number(newarr[2])==1){
                  item.xingtai='顺子'
                }
               
              }
            
              item.dx = sum > 13 ? "大" : "小";
              item.ds = sum % 2 == 0 ? "双" : "单";
              item.jizhi='无';
              if(sum>=0&&sum<=5){
                item.jizhi='极小'
              }
              if(sum>=22&&sum<=27){
                item.jizhi='极大'
              }
              item.codeArr.push(sum);
            });
          }
          // 翻摊
          if (this.$options.name == "kjfantan") {
            this.list.forEach((item) => {
              if(item.Opencode.includes("+")){
                let obj=this.fantangameopencode(item.codeArr)
                let sum = obj.sum;
              
                item.sum=sum
              
               item.daxiao=obj.dx  
            
              }else{
                let sum = 0;
                item.codeArr.forEach((num) => {
                  sum +=Number(num);
                });
                item.sum=sum
                let yu = Number(sum) % 4;
                yu = yu == 0 ? 4 : yu;
                item.fan=yu+'番'
               item.daxiao=sum>22?'大':'小'  
                item.ds = sum % 2 == 0 ? "双" : "单";
              }
          
            });
          }
          // 宾果彩
          if (this.$options.name == "kjbgc") {
            this.list.forEach((item) => {
              item.resultArr = [];
              item.codeArr = item.codeArr.splice(0, 5);
              item.codeArr.forEach((code) => {
                let obj = {};
                obj.dx = code > 40 ? "大" : "小";
                obj.ds = code % 2 == 0 ? "双" : "单";
                item.resultArr.push(obj);
              });
              item.lutuResult = this.bgclutu(item.codeArr);
            });
          }
          // }
          this.totalPage = resObj.maxpagenum;
          this.totals = resObj.maxrecordnum;
          // console.log(resObj);
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 当前页面
    handleCurrentChange(page) {
      this.pages.pagenum = page;
      this.getList();
    },
  },
};

export default mixins;
